<template>
  <div id="casos">
    <div class="vista">
      <div class="row w-full">

        <vs-table :data="casos" max-items="10" pagination filter noDataText="No hay datos para mostrar" class="" search>
          <template slot="header">
            <div class="row w-full" style="">
              <h4 class="lista">Consulta buro de credito</h4>
            </div>
          </template>

          <template slot="header">

            <div class="row content-disponibilidad">
              <div class="col-6 col-sm-6 col-md-10 text-center">
                Consultas restantes: {{this.consultasDisponibles}}
              </div>
              <div class="col-6 col-sm-6 col-md-2 text-end" v-if="this.accessBuroCredito">
                <button type="button" class="btn btn-success" @click="openRegistro()" :disabled="validateForm">Solicitar Reporte</button>
              </div>
            </div>

          </template>

          <template slot="thead">
            <vs-th>
              Folio de Consulta
            </vs-th>
            <vs-th>
              Folio de Caso Completo
            </vs-th>
            <vs-th>
              Fecha
            </vs-th>
            <vs-th>
              Solicitante
            </vs-th>
            <vs-th>
              Correo
            </vs-th>
            <vs-th>
              Usuario
            </vs-th>
            <vs-th>
              Organizacion
            </vs-th>
            <vs-th>
              Reporte
            </vs-th>

          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="data[indextr].Id">
                {{data[indextr].Id}}
              </vs-td>

              <vs-td :data="data[indextr].Id">
                {{data[indextr].Caso}}
              </vs-td>

              <vs-td :data="data[indextr].Id">
                {{data[indextr].Fecha}}
              </vs-td>

              <vs-td :data="data[indextr].Id">
                {{data[indextr].SolicitanteNombre}}
              </vs-td>

              <vs-td :data="data[indextr].Id">
                {{data[indextr].Solicitante}}
              </vs-td>

              <vs-td :data="data[indextr].Id">
                {{data[indextr].Broker}}
              </vs-td>
              <vs-td :data="data[indextr].Id">
                {{data[indextr].NombreOrganizacion}}

              </vs-td>

              <vs-td :data="data[indextr].Id" v-if="data[indextr].ConError == '0'" class="celda-enlace">
              <img :src="'https://cotizador.socasesores.com/app/img/IconoVerde.PNG'"  @click="AbrirReporte(data[indextr].Id)"  class="enlace-m"> 
              </vs-td>


              <vs-td :data="data[indextr].Id" v-if="data[indextr].ConError == '1'" class="celda-enlace">
                <vx-tooltip :text='data[indextr].ErrorString' 
                position="top"
                color="danger">
                  <img :src="'https://cotizador.socasesores.com/app/img/IconoRojo.PNG'" class="enlace-m">
                </vx-tooltip>
              </vs-td>
            </vs-tr>
          </template>
        
        </vs-table>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    guid: {
      type: String,
      required: false,
    },
  },

  data () {
    return {
			casos: [],
      key: '',
			casos: [],
      allcasos: [],

      allFilterCasos:[],

      estatus: [],
      estatu: '',

      popupActive: false,
      activePrompt: false,

      accessBuroCredito: false,
      consultasDisponibles: 0,
      // count: 0,
    }
  },

  computed:{
    validateForm(){
      return this.consultasDisponibles == 0 ||  this.consultasDisponibles == '' 
    }
  },

  mounted () {
    if ( this.guid ) {

      const word = this.guid
      const isInmo = word.includes("@")
      isInmo ? this.verificaUsuario() : this.ObtenCredenciales()

    } else {

      const hasAccess = JSON.parse(localStorage.getItem("userInfo"))
      hasAccess ? this.getApikey() : this.$router.push("/login").catch(() => {})

    }

    const { BuroCredito } = JSON.parse(localStorage.getItem('userInfo'))
    this.accessBuroCredito = BuroCredito

    if (
      this.$store.state.AppActiveUser.WidgetColorPrimario != "" &&
      this.$store.state.AppActiveUser.WidgetColorPrimario
    ) {
      this.$vs.theme({
        primary: this.$store.state.AppActiveUser.WidgetColorPrimario,
      })
    }

    this.$vs.loading({
      container: '#casos',
      scale: 0.6
    })

    this.getTotalConsultas()
  },

  watch: {
    estatu: function() {
      if (this.estatu == null) {
        this.casos= this.allcasos
      }else {
        this.filter(this.estatu)
      }
    }
  },

  methods: {
    getApikey(){
      const { ApiKey } = JSON.parse(localStorage.getItem('userInfo'))

      this.key = ApiKey
    },

    AbrirReporte(IdConsulta){
      window.open(`https://cotizador.socasesores.com/admin/formatos/Formato_Reporte_Buro_Credito_14_09_2023.php?intId=${IdConsulta}`)
    },

    openRegistro() {

      this.$router.push('/ConsultaBuroCredito')

    },

    async getTotalConsultas () {

      try {

        const consultasSISEC = await this.getConsultasSISEC()
        const { Total, ReasonBlocked, IsPermitted } = consultasSISEC

        if ( !IsPermitted ) {

          this.consultasDisponibles = 0

          return this.$vs.notify({
            title: 'Ocurrio un error',
            text: ReasonBlocked,
            color: 'danger',
            position: 'top-right'
          })

        } 

        this.consultasDisponibles = Total

        this.getSolicitudes()
        
      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }

    },

    async getTokenSisec () {
      try {
        const objRequest = {
          username: 'user',
          password: 'user',
          grant_type: 'password'
        }

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/token'

        let formBody = [];
        for (const property in objRequest) {
          let encodedKey = encodeURIComponent(property)
          let encodedValue = encodeURIComponent(objRequest[property])
          formBody.push(`${encodedKey}=${encodedValue}`)
        }
        formBody = formBody.join("&")

        const data = await this.$axios.post(URL_API_SISEC, formBody,{headers : {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}})

        return data

      } catch (error) {

        console.error('Token error')

        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }
    },

    async getConsultasSISEC () {
      try {
        
        const TOKEN_SISEC = await this.getTokenSisec()
        const { access_token } = TOKEN_SISEC.data

        const userInfoJson = localStorage.getItem('userInfo')
        const { SocGuid } = JSON.parse(userInfoJson)
  
        if ( !SocGuid ) {

          return { Total: 0, ReasonBlocked: 'El bróker no tiene la facultad de realizar consultas.', IsPermitted: false }

        }

        const objRequest = {
          EmployeeSOCId: SocGuid,
          PersonType: 'Fisico'
        }

        //const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado' // PROD

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/BuroCredito/Estado' // buro de credito

        const resp = await this.$axios.post(URL_API_SISEC, objRequest, { 
          headers : {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        const { status, data } = resp

        if ( status !== 200 ) {
          this.$vs.notify({
            title:'Ocurrio un error en Consultas disponibles',
            text:'Ocurrió un problema, inténtelo nuevamente más tarde.',
            color:'danger',
            position:'top-right'
          })

          return { Total: 0, ReasonBlocked: 'Ocurrió un problema, inténtelo nuevamente más tarde.', IsPermitted: false }
        }

        return data

      } catch (error) {
        console.error('Availability error')

        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }
    },

    async getSolicitudes(){

      try {
        
        const objRequestListaCasos = {
          strApiKey: this.key,
          strMetodo: 'ListaBuroCreditoEjecutivoApiKey',
          objEjecutivo: {
            ApiKey: this.key
          }
        }
        
        const { data } = await this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        const { intStatus, objContenido } = data
        
        this.casos = objContenido
        this.allcasos = this.casos

        // for (const caso of this.casos) {
        //   const { Id, Caso, Fecha, Solicitante, Correo, Broker, NombreOrganizacion, ConError, ErrorString } = caso
          
          // console.log({ Id, Caso, Fecha, Solicitante, Correo, Broker, NombreOrganizacion, ConError, ErrorString })

          // if ( ConError && ErrorString ) {}

        // }

        this.$vs.loading.close('#casos > .con-vs-loading')

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema en ListaBurosEjecutivoApiKey',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }
		},
  }
}
</script>

<style lang="css" scoped>
  th {
    text-align: left;
    padding-top: 90px !important;
    padding-right: 20px !important;
  }

  .vs-con-table .vs-con-tbody {
    background: #fff !important;
  }

  .vs-input {
    padding: 0px;
    border-radius: 0 5px 5px 0 !important;
  }

  .vs-input2 {
    padding: 0px;
    border-radius: 5px !important;
    border: none;
    margin-top: 10px;
    max-width: 190px !important;

  }

  .vs-input3 {
    padding: 0px;
    border-radius: 0 5px 5px 0 !important;
    border: none;
    margin-top: 10px;
    max-width: 180px !important;
    margin-left: -6px;

  }

  .content-disponibilidad {
    color:#015694; 
    font-weight:600;
    top: 95px;
    position: relative;
    z-index: 666;
    padding: 0 5px;
  }

  select {
    padding: 0.55rem !important;
  }

  .alinear {
    margin-bottom: 10px;
  }

  .lupa {
    margin-top: 10px;
    border: none;
    padding: 6px;
    background: #fff;
    border-radius: 5px 0 0 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    margin-left: 255px;
  }



  .vuesax-app-is-ltr .vs-table--search-input {
    /*padding-left: 1.5em !important;*/
  }

  select#estatus.search-input {
    border: none !important;
  }



  .w-full {
    padding-top: 0 !important;
  }

  .excel {
    float: right;
    margin-right: 20px;
    position: relative;
    top: -50px;
    font-weight: 600;
  }

  .colorNuevo {
    background: #B7DCB8;
  }

  @media screen and (max-width:1180px) {
    h4.lista {
      width: 200px;
    }

    .lupa {
      border-radius: 5px 0 0 5px;
      margin-left: 150px;
    }

    .vs-input2 {
      margin-left: 150px;
    }
  }

  @media screen and (max-width:1024px) {
    h4.lista {
      position: relative;
      top: 10px;
      width: 100%;
    }

    .lupa {
      margin-left: 90px;
    }

    .vs-input2 {
      margin-left: 100px;
    }


  }

  @media screen and (max-width:915px) {

    .vs-input2,
    .lupa {
      margin-left: 0px;
      background-color: #fff !important;
    }

    .vs-input2,
    .vs-input3 {
      max-width: 100% !important;
      width: 100%;
      background-color: #fff !important;
    }

    .alinear-input {
      margin-right: auto;
      margin-left: auto;
    }

    .excel {
      float: none;
      margin-right: auto;
      margin-left: auto;
      top: 0;
      max-width: 300px;
    }

    .col-md-1 {
      width: 100%;
    }

    h4.lista {
      position: relative;
      top: 50px;
      width: 100%;
    }

  }

  @media screen and (max-width:896px) {

    .vs-input2,
    .lupa {
      margin-left: 0px;
      background-color: #fff !important;
    }

    .vs-input2,
    .vs-input3 {
      max-width: 100% !important;
      width: 100%;
      background-color: #fff !important;
    }

    .alinear-input {
      margin-right: auto;
      margin-left: auto;
    }

    .excel {
      float: none;
      margin-right: auto;
      margin-left: auto;
      top: 0;
      max-width: 300px;
    }

    .col-md-1 {
      width: 100%;
    }

    h4.lista {
      position: relative;
      top: 50px;
      width: 100%;
    }

  }

  @media screen and (max-width:768px) {

    .vs-input2,
    .lupa {
      margin-left: 0px;
      background-color: #fff !important;
    }

    .vs-input2,
    .vs-input3 {
      max-width: 100% !important;
      width: 100%;
      background-color: #fff !important;
    }

    .alinear-input {
      margin-right: auto;
      margin-left: auto;
    }

    .excel {
      float: none;
      margin-right: auto;
      margin-left: auto;
      top: 0;
      max-width: 300px;
    }

    h4.lista {
      width: 300px !important;
      top: 50px;
      position: relative;

    }
  }

  @media screen and (max-width:667px) {

    .vs-input2,
    .lupa {
      margin-left: 0px;
      background-color: #fff !important;
    }

    .vs-input2,
    .vs-input3 {
      max-width: 100% !important;
      width: 100%;
      background-color: #fff !important;
    }

    .alinear-input {
      margin-right: auto;
      margin-left: auto;
    }

  }

  @media screen and (max-width:656px) {

    .vs-input2,
    .lupa {
      margin-left: 0px;
      background-color: #fff !important;
    }

    .vs-input2,
    .vs-input3 {
      max-width: 100% !important;
      width: 100%;
      background-color: #fff !important;
    }

    .alinear-input {
      margin-right: auto;
      margin-left: auto;
    }

    .excel {
      float: none;
      margin-right: auto;
      margin-left: auto;
      top: 0;
      max-width: 300px;
    }
  }
</style>